import { __decorate } from "tslib";
import { Vue, Component, Provide } from 'vue-property-decorator';
let EmptyRouterView = class EmptyRouterView extends Vue {
    constructor() {
        super(...arguments);
        // TODO: move to store
        this.organizationId = +this.$route.params.id;
    }
};
__decorate([
    Provide()
], EmptyRouterView.prototype, "organizationId", void 0);
EmptyRouterView = __decorate([
    Component({})
], EmptyRouterView);
export default EmptyRouterView;
